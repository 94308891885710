

























































import Vue from 'vue'
import { ReviewListData, PageData } from 'HealthAdvisorBackendApp'
import { ReviewItem } from 'HealthAdvisorFrontendApp'
import VueScrollTo from 'vue-scrollto'
import {
  API_PAGE_SIGNUP,
  PRACTITIONER_LABEL,
  PRACTITIONERS_LABEL,
  WEBSITE_NAME
} from '@/utils/settings'
import { routes } from '@/utils/routes'
import NavbarHeader from '@/components/shared/NavbarHeader/index.vue'
import ValuePoints, {
  ValuePoint
} from '@/components/listPractice/ValuePoints/index.vue'
import PricingModel from '@/components/listPractice/PricingModel/index.vue'
import AboutUs from '@/components/listPractice/AboutUs/index.vue'
import FaqsSection, { FAQ } from '@/components/listPractice/Faqs/index.vue'
import WhyJoin, {
  ListPracticeReasonWhy
} from '@/components/listPractice/WhyJoin/index.vue'
import TestimonialsSlider, {
  Testimonial
} from '@/components/listPractice/Testimonials/index.vue'
import LandingPageHero from '@/components/shared/LandingPageHero/index.vue'
import SignupHeroImage from '~/static/images/login-img.svg'
import { apiFetcher } from '@/utils/api/fetcher'
import { mapApiReviewDataToReviewSlider } from '@/utils/api/review'

interface BvModalRoot extends Vue {
  $bvModal: {
    show: (modalId: string) => void
    hide: (modalId: string) => void
    toggle: (modalId: string) => void
  }
}

const valuePoints: ValuePoint[] = [
  {
    title: 'List Your Practice',
    description: `Get in front of patients searching for an ${PRACTITIONER_LABEL.toLowerCase()} in your area. No listing fees.`
  },
  {
    title: 'Manage Your Bookings',
    description:
      'Instant free access to our enterprise grade practice management software'
  },
  {
    title: 'Grow Your Business',
    description:
      'Build your online reputation. If we don’t deliver patients you don’t pay'
  }
]

const aboutUsTextLeft = `
<p>
  ${WEBSITE_NAME} is a digital technology company operated by Health Advisor Limited.
  Our primary focus is to provide on-demand access to healthcare appointments across
  the whole of the UK.
</p>
`
const aboutUsTextRight = `
<p>
  We are building a nationwide network of ${PRACTITIONERS_LABEL.toLowerCase()} and welcome new practices
  looking to increase bookings to join our rapidly growing network. If you are a
  practitioner or practice manager looking for more patient bookings then we’d love
  to hear from you.
</p>
`

const ReasonWhy1: ListPracticeReasonWhy = {
  iconUrl: require(`~/static/images/signup-why-revenue.png`),
  title: 'Increase Your Revenue',
  description: 'Fill slots in your diary and boost your income'
}

const ReasonWhy2: ListPracticeReasonWhy = {
  iconUrl: require(`~/static/images/signup-why-nofuss.png`),
  title: 'No-Fuss Sign-Up',
  description:
    'Automated sign up and results dashboard. And we’re always here to help!'
}

const ReasonWhy3: ListPracticeReasonWhy = {
  iconUrl: require(`~/static/images/signup-why-national-exposure.png`),
  title: 'National Exposure',
  description:
    'Boost your reputation and get known beyond your local area for remote appointments'
}

const ReasonWhy4: ListPracticeReasonWhy = {
  iconUrl: require(`~/static/images/signup-why-performance.png`),
  title: 'Pay For Preformance',
  description: 'Only pay for real patients. You’re always in charge.'
}

export default Vue.extend({
  name: 'ListPractice',
  components: {
    NavbarHeader,
    LandingPageHero,
    ValuePoints,
    PricingModel,
    AboutUs,
    FaqsSection,
    WhyJoin,
    TestimonialsSlider
  },
  async asyncData({ $axios }) {
    const start = new Date().getTime()

    // ALL API DATA
    const allApiData = await Promise.all([
      apiFetcher<PageData>({
        $axios,
        apiUrl: `/page/${API_PAGE_SIGNUP}/`
      }),
      apiFetcher<ReviewListData>({
        $axios,
        apiUrl: `/reviews/?limit=10`
      })
    ])
    const [fetchedPageData, fetchedReviews] = allApiData

    const end = new Date().getTime()

    // SEO DATA
    const { head, schemaJsonLD } = fetchedPageData

    // REVIEWS DATA
    const reviews: ReviewItem[] = mapApiReviewDataToReviewSlider(
      fetchedReviews.responseData
    )
    const testimonials: Testimonial[] = (reviews || []).map((item) => ({
      avatarUrl: item.avatarUrl,
      fullName: item.name,
      placeName: '',
      text: item.text.length > 173 ? item.text.slice(0, 170) + '...' : item.text
    }))

    const faqs: FAQ[] =
      fetchedPageData.responseData.data &&
      fetchedPageData.responseData.data.faq &&
      fetchedPageData.responseData.data.faq.length
        ? fetchedPageData.responseData.data.faq.map((faq) => ({
            title: faq.question || '',
            text: faq.answer || ''
          }))
        : []

    return {
      head,
      schemaJsonLD,
      testimonials,
      faqs,
      perf: end - start
    }
  },
  data() {
    return {
      heroImage: SignupHeroImage,
      valuePoints,
      aboutUsTitle: 'The UK’s Largest Network of Healthcare Professionals',
      aboutUsTextLeft,
      aboutUsTextRight,
      whyJoinTitle: `Why Join ${WEBSITE_NAME}?`,
      reasons: [ReasonWhy1, ReasonWhy2, ReasonWhy3, ReasonWhy4]
    }
  },

  mounted() {
    console.info('perf', this.$data.perf)

    // nuxt default scrollbehavior breaks anchors, using vueScrollTo
    // https://github.com/nuxt/nuxt.js/issues/5359
    const scrollToHash = this.$route.hash
    if (scrollToHash && VueScrollTo) {
      VueScrollTo.scrollTo(scrollToHash.replace('/', ''), 0, { offset: -60 })
    }
  },
  methods: {
    navigateToRegistration() {
      this.$router.push(routes.onboardingStep1.makeUrl())
    }
  },
  jsonld() {
    return this.$data.schemaJsonLD || null
  },
  head() {
    return this.$data.head
      ? {
          ...this.$data.head
        }
      : {}
  }
})
